import React from "react"
import theme from "theme"
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Serenity Waves Spa & Wellness Resort</title>
        <meta
          name={"description"}
          content={"Where Tranquility Meets Revitalization\n"}
        />
        <meta
          property={"og:title"}
          content={"Serenity Waves Spa & Wellness Resort"}
        />
        <meta
          property={"og:description"}
          content={"Where Tranquility Meets Revitalization\n"}
        />
        <meta
          property={"og:image"}
          content={"https://movilespin.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://movilespin.com/img/5099647.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://movilespin.com/img/5099647.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://movilespin.com/img/5099647.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://movilespin.com/img/5099647.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://movilespin.com/img/5099647.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://movilespin.com/img/5099647.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        color="--light"
        padding="100px 0 280px"
        sm-padding="40px 0"
        position="relative"
        background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://movilespin.com/img/map.png)"
		background-position="50%"
        quarkly-title="Form-1"
      >
        <Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
          <Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
            <Text
              margin="0px 0px 30px 0px"
              font="--headline1"
              color="--light"
              text-align="left"
              sm-font='normal 700 62px/1.2 "Source Sans Pro", sans-serif'
            >
              Get in Touch{"  "}
            </Text>
            <Text
              margin="0px 0px 48px 0px"
              font="--lead"
              color="--light"
              text-align="left"
            >
              Embark on your path to relaxation with Serenity Waves. To book
              your visit or for more information, here's how you can reach us:
            </Text>
          </Box>
          <Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
            <Box>
              <Box
                sm-padding="64px 0 0 0"
                margin="32px 0 0 0"
                max-width="360px"
                position="relative"
                padding="0 0 0 64px"
              >
                <Icon
                  size="48px"
                  top="0"
                  left="0"
                  category="md"
                  icon={MdLocationOn}
                  position="absolute"
                />
                <Text as="h4" margin="6px 0" font="--base">
                  Location
                </Text>
                <Text as="p" margin="6px 0" font="--headline3">
                  25 Henry St, Stepney SA 5069, Australia
                </Text>
              </Box>
              <Box
                position="relative"
                padding="0 0 0 64px"
                sm-padding="64px 0 0 0"
                margin="64px 0 0 0"
                max-width="360px"
              >
                <Icon
                  top="0"
                  left="0"
                  category="md"
                  icon={MdEmail}
                  position="absolute"
                  size="48px"
                />
                <Text font="--base" as="h4" margin="6px 0">
                  Email
                </Text>
                <Text as="p" margin="6px 0" font="--headline3">
                  <Link
                    href="mailto:info@movilespin.com"
                    text-decoration="none"
                    hover-text-decoration="underline"
                    color="--light"
                  >
                    info@movilespin.com
                  </Link>
                </Text>
              </Box>
              <Box
                padding="0 0 0 64px"
                margin="64px 0 0 0"
                max-width="360px"
                position="relative"
              >
                <Icon
                  left="0"
                  category="md"
                  icon={MdPhone}
                  position="absolute"
                  size="48px"
                  top="0"
                />
                <Text font="--base" as="h4" margin="6px 0">
                  Phone
                </Text>
                <Text as="p" margin="6px 0" font="--headline3">
                  +61 8 8362 7764
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
